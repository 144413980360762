import { createRouter, createWebHistory } from "vue-router";
//import store from "@/store";

const HomeView = () => import(/* webpackChunkName: "home" */ "@/views/home/HomeView");

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: { title: "Home" }
    },
    {
        path: "/:catchAll(.*)",
        redirect: { path: "/" }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) =>
{
    // Not inline because of 
    console.log(`Router from:`, from);
    console.log(`Router to:`, to);

    next();
});

router.afterEach((to, from, failure) =>
{
    console.log(`Router has loaded: `, to);
    console.log(`State: ${failure ? 'Failed' : 'Success'}`);
    if (!failure)
    {
        // sendToAnalytics(to.fullPath)
    }
})

export default router;