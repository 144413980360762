import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

import FontAwesomeIcon from "./fontawesome-icons";

try
{
    console.log(`App version - ${process.env.VUE_APP_VERSION}`);
    console.log(`Build - ${process.env.VUE_APP_BUILD_NUMBER}`);
}
catch (e)
{
    console.error(e);
}

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .use(store)
    .mount('#app')