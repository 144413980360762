<template>
<!--  <div class="app-version" v-show="false"> {{ appVersion }}</div>-->
  <router-view></router-view>
</template>

<script>
import store from "@/store";

export default {
  store,
  name: "App",
  components: {},
  setup()
  {
    return {
      appVersion: process.env.VUE_APP_VERSION
    }
  }
}
</script>

<style lang="scss">
@import "./styles/main";

#app
{
  background-color: $background-neutral;
  color: $black;
  height: 100%;
}
</style>
