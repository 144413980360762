import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

// import { faPhone } from "@fortawesome/free-solid-svg-icons";
//
// library.add(faPhone);

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas, far);

export default FontAwesomeIcon;